/* WaveFormVisualization.module.css */
/* Ensure the visualization container is appropriately sized */
.visualizationContainer {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px;
	box-sizing: border-box;
}

/* Full-Screen Mode */
.fullScreen {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 999; /* On top in full-screen mode */
	background-color: #000; /* Black background for full-screen */
}

/* Exit Button Styling */
.exitButton {
	position: absolute;
	bottom: 20px;
	right: 20px;
	padding: 10px 20px;
	background-color: #ff6347; /* Tomato color */
	color: #fff;
	border: none;
	cursor: pointer;
	font-size: 16px;
	z-index: 1000; /* Ensure it's above other elements */
	border-radius: 8px;
}

.exitButton:hover {
	background-color: #ff4500; /* Darker on hover */
}

.visualizationWrapper {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

/* Media Queries for Mobile Adjustment */
@media (max-width: 640px) {
	.visualizationContainer {
		height: 80vh;
		padding: 10px;
	}
}

/* src/components/CrystalOrbVisualization.module.css */

.visualizationContainer {
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #10101a; /* Dark background */
}

.visualizationWrapper {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.loader {
	width: 50px;
	height: 50px;
	border: 6px solid #ccc;
	border-top: 6px solid #333;
	border-radius: 50%;
	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

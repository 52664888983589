@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::before,
*::after {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

body {
	scroll-behavior: smooth;
}

/* General Styling */
.pageBackground {
	background: linear-gradient(
		135deg,
		#6c5ce7,
		#2d3748
	); /* Vibrant gradient for a modern look */
	padding: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
	background-size: cover;
	background-position: center;
	margin-top: 4rem;
}

/* Consistent Heading Style */
.heading {
	font-family: 'Montserrat', sans-serif;
	color: white;
	text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
	animation: fade-in 1s ease-in-out;
	font-size: 2.5rem;
}

/* Input Fields */
.inputField {
	width: 100%;
	border-radius: 0.375rem !important;
	border: none;
	padding: 0.75rem 1rem;
	color: white;
	background-color: rgba(255, 255, 255, 0.1) !important;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
	font-size: 1rem;
	transition: background-color 0.3s ease, box-shadow 0.3s ease;
	padding-right: 2.5rem;
}

.inputField:focus {
	background-color: rgba(255, 255, 255, 0.2);
	box-shadow: 0 0 0 3px rgba(124, 58, 237, 0.6);
	outline: none;
}

/* Sign Up button */
.signUpButton {
	width: 100%;
	padding: 1rem 1.5rem;
	background-color: #7f5bdf !important;
	color: white;
	border-radius: 0.5rem !important;
	font-size: 1.1rem;
	font-family: 'Roboto', sans-serif;
	font-weight: 600;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
	transition: background-color 0.3s ease, transform 0.3s ease;
}

.signUpButton:hover {
	background-color: #9b70f9;
	transform: translateY(-3px);
}

/* Fade-in animation */
@keyframes fade-in {
	from {
		opacity: 0;
		transform: translateY(-10px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

/* Additional Styling for Eye Icon */
button {
	background: none;
	border: none;
}

.relative {
	position: relative;
}

.absolute {
	position: absolute;
}

.mt-6 {
	margin-top: 1.5rem;
}

.mb-6 {
	margin-bottom: 1.5rem;
}
